.footer {
	width: 100%;
	padding: 40px 10px 10px;
	background: $DARK_BLUE;
	color: $WHITE;

	&-nav {
		display: flex;
		margin: 0;
		padding: 0;
		flex-flow: row wrap;
		list-style: none;

		&-item {
			width: 100%;
			padding: 0;
			margin: 0;

			a {
				color: $WHITE;
				text-decoration: none;
			}

			& + & {
				margin-top: 10px;
			}
		}
	}

	@media (max-width: $md) {
		[class^='col-'] + [class^='col-'] {
			margin-top: 20px;
		}
	}

	&-copy {
		margin-top: 30px;
		@include fontSize(10);
	}
}
