.hero {
	position: relative;
	width: 100%;
	min-height: calc(100vh - 70px);

	.container,
	.row,
	&-content {
		min-height: 100vh;
	}

	&-content {
		display: flex;
		flex-flow: row wrap;
		align-content: center;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			width: 100%;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@include fontSize(44);
		}
	}

	&-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($BLACK, 0.5);
		}
	}
}
