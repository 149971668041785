.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	padding: 10px;
	background: $DARK_BLUE;
	box-shadow: 0px 0px 28px 9px rgba($BLACK, 0.23);
	z-index: 999;

	.row {
		flex-flow: row nowrap;
	}

	&-logo {
		width: 100px;
		margin: 0;
		padding: 0;

		img {
			width: 100%;
			height: auto;
		}
	}

	&-items {
		display: flex;
		flex: 1;
		flex-flow: row wrap;
		align-content: center;
		list-style: none;
	}

	&-item {
		padding: 0;
		margin: 0;

		a {
			color: $WHITE;
			text-decoration: none;
		}
	}
}
