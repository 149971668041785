.page {
	min-height: 100vh;
	padding-top: 70px;

	&-not-found {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	&-home {
		.hero {
			.hero-content {
				h1,
				h2 {
					@media (min-width: $md) {
						text-align: center;
					}
				}

				h2 {
					color: $WHITE;
				}

				h1 {
					color: $BLUE;
				}
			}
		}

		section {
			padding: 40px 0;
		}
	}
}
