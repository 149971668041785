*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	max-width: 100vw;
	font-family: $mainFont;
	color: $BLACK;
	overflow-x: hidden;
}

a {
	color: $BLACK;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $DARK_BLUE;
}
