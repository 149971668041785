@use "sass:math";

.container{
    display: flex;
    width: 100%;
    padding: 0 20px;
    max-width: $containerWidth;
    margin: 0 auto;
    flex-wrap: wrap;
}

.row{
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    flex-shrink: 0;
}

@mixin responsiveGrid ($size: 'xs'){
    @for $i from 1 through $columns{
        .col-#{$size}-#{$i} {
            width: percentage(math.div($i,$columns));
            padding: 0 $gutter;
        }
    }
}

@include responsiveGrid;

@include respond-to (min-width, $sm){
    @include responsiveGrid('sm');
}

@include respond-to (min-width, $md){
    @include responsiveGrid('md');
}

@include respond-to (min-width, $lg){
    @include responsiveGrid('lg');
}

@include respond-to (min-width, $xl){
    @include responsiveGrid('xl');
}
